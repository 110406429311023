import { createContext } from 'react';


export const page = createContext({
  pageId: -1,
  isMobile: 0,
  isTablet: 0,
  isDesktop: 0,
  isPublicDomain: 0,
  appSettings: [],
  destinationId: null,
  propertiesParams: {
    page_id: null,
    limit: 24,
    offset: 0,
    destinations: [],
    tags: [],
    themes: [],
    locations: [],
    statuses: 3
  }
});