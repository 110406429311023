import React, { Suspense, useContext, useState } from 'react';
import Redis from 'ioredis'
import dynamic from 'next/dynamic';

import * as appContext from '../components/AppContext';
import BuilderHead from '../components/head/BuilderHead';
import { InlineStyles } from '../components/templates/index/partials/InlineStyles';
import Checkers from '../helpers/class/Checkers';
import * as DataFetchHelper from '../helpers/DataFetchHelper';
import * as MethodHelper from '../helpers/MethodHelper';
import {setIsHomePage, setPageId} from '../redux/actions';
import { wrapper } from '../redux/store';
import MainLayout from "../layouts/MainLayout";

const IndexSections = dynamic( () => import( '../components/templates/index/sections/IndexSections' ), { suspense: true });
const Addons = dynamic( () => import( '../components/Addons' ) );
const Scripts = dynamic( () => import( '../components/Scripts' ), { ssr: false });

export default function Home ( props ) {

  const pageContext = useContext( appContext.page );
  pageContext.pageId = props.app_data.page_id;

  pageContext.isMobile = props.isMobile;
  pageContext.isTablet = props.isTablet;
  pageContext.isDesktop = props.isDesktop;

  pageContext.appSettings = props.app;

  const [ urlHref, setUrlHref ] = useState( null );
  React.useEffect( () => {
    if ( window !== undefined ) {
      setUrlHref( window.location.href );
    }
  }, [urlHref]);

  const domain = props.domain;
  const app = props.app;
  const builder = app.builder;
  const head = props.head;
  const footer = props.footer;

  const app_data = props.app_data;
  const locations = props.locations;
  const navigation = props.navigation;
  const content = props.content;
  const rgbDesignColor = props.rgbDesignColor;
  const rgbSearchbarColor = props.rgbSearchbarColor;
  const html_sections = props.html_sections;
  const script_sections = props.script_sections;

  const distincts = props.distincts;
  const profile = props.profile;
  const destinations = props.destinations;
  const collections = props.collections;

  const isDesktop = props.isDesktop;

  const image_fb_meta = Checkers.isValidArray( head.og_image ) ? head.og_image[0].image : null;

  return (
      <MainLayout {...props}>
        <InlineStyles app={app} rgbDesignColor={rgbDesignColor} rgbSearchbarColor={rgbSearchbarColor}/>
        <BuilderHead
            title={head.data.title + ' - ' + head.data.motto + ' | Home'}
            keywords={head.data.meta_keywords}
            titleOfficial={head.data.titleOfficial}
            domain={urlHref}
            icon_60={head.data.icon_60}
            icon_76={head.data.icon_76}
            icon_120={head.data.icon_120}
            icon_152={head.data.icon_152}
            icon_256={head.data.icon_256}
            icon={head.data.icon}
            title_fb_meta={head.data.title + ' - ' + head.data.motto}
            desc_fb_meta={head.data.meta_description}
            image_fb_meta={image_fb_meta}
            site_name_fb_meta={head.data.title}
            lat_fb_meta={head.location.latitude}
            lon_fb_meta={head.location.longitude}
            twitter_img_alt={head.data.title + ' - ' + head.data.motto + '. ' + head.data.meta_description}
            googleSchemaContext={domain}
            googleSchemaTitle={head.data.title}
            googleSchemaStreetAddress={head.location.address}
            googleSchemaCity={head.location.city}
            googleSchemaCountry={head.location.country}
            googleSchemaZip={head.location.zip}
            googleSchemaPhone={head.location.phone}
            designColor={app.designColor}
            font={app.font}
            manager={false}
        />

        <Suspense>
          <IndexSections
              isDesktop={isDesktop}
              html_sections={html_sections.hasOwnProperty( 'app_sections' ) ? html_sections.app_sections != null && html_sections.app_sections.length > 0 ? html_sections.app_sections : null : null}
              app_data={app_data}
              head={head}
              app={app}
              builder={builder}
              navigation={navigation}
              content={content}
              locations={locations}
              footer={footer}
              distincts={distincts}
              profile={profile}
              destinations={destinations}
              collections={collections}
              isHomepage={true}
              categoriesContentDefault={props.categoriesContentDefault}
              categoriesContentPromoted={props.categoriesContentPromoted}
              categoriesContentPromoted2={props.categoriesContentPromoted2}
              experienceCategoriesContentDefault={props.experienceCategoriesContentDefault}
              experienceCategoriesContentPromoted={props.experienceCategoriesContentPromoted}
              experienceCategoriesContentPromoted2={props.experienceCategoriesContentPromoted2}
          />
        </Suspense>

        {/*<Scripts/>*/}
        <Addons
            script_sections={script_sections.hasOwnProperty( 'app_sections' ) ? script_sections.app_sections != null && script_sections.app_sections.length > 0 ? script_sections.app_sections : null : null}
        />
      </MainLayout>
  )
}



// export async function getServerSideProps ({ req, res }) {
export const getServerSideProps = wrapper.getServerSideProps( ( store ) => async (
    { req,
      res,
      query
    }) => {

  const [ isMobile, isTablet,  isDesktop ] = Checkers.getUserAgentData( store, req )

  res.setHeader(
      'Cache-Control',
      'public, s-maxage=10, stale-while-revalidate=59'
  )
  const redis = new Redis( process.env.REDIS );
  let subdomain = null;
  const slug_data = null;

  let head = null;
  let app = null;
  let page = null;
  let app_data = null;
  let navigation = null;
  let content = null;
  let categoriesContentDefault = []
  let categoriesContentPromoted = []
  let categoriesContentPromoted2 = []
  let experienceCategoriesContentDefault = []
  let experienceCategoriesContentPromoted = []
  let experienceCategoriesContentPromoted2 = []
  let html_sections = null;
  let script_sections = null;
  let locations = null;
  let distincts = null;
  let categories = null;
  let profile = null;
  let destinations = null;
  let collections = null;
  let footer = null;
  let rgbSearchbarColor = null;
  let rgbDesignColor = null;

  let manifest = null;

  const domain = req.headers.host;
  const hostname_parts = req.headers.host.split( '.' );
  const parts = hostname_parts.length;

  app_data = await DataFetchHelper.fetchAppData( req, redis, res );

  store.dispatch( setPageId( app_data.page_id ) )

  store.dispatch( setIsHomePage( 1 ) )

  await DataFetchHelper.fetchSystemData( redis, app_data.page_id, store ).then( ( response ) => {
    head = response.head
    app = response.app
    page = response.page
    navigation = response.navigation
    locations = response.locations
    footer = response.footer
    html_sections = response.html_sections
  })

  script_sections = await DataFetchHelper.fetchScriptSectionsData( redis, app_data.page_id,  )

  content = await DataFetchHelper.fetchContentData( redis, app_data.page_id )

  distincts = await DataFetchHelper.fetchDistinctsData( redis, app_data.page_id )

  await DataFetchHelper.fetchHomepageCategoriesData( redis, app_data.page_id).then ( ( response ) => {
    categoriesContentDefault = response.categoriesContentDefaultRes
    categoriesContentPromoted = response.categoriesContentPromotedRes
    categoriesContentPromoted2 = response.categoriesContentPromoted2Res
  })

  await DataFetchHelper.fetchHomepageExperiencesCategoriesData( redis, app_data.page_id).then ( ( response ) => {
    experienceCategoriesContentDefault = response.categoriesContentDefaultRes
    experienceCategoriesContentPromoted = response.categoriesContentPromotedRes
    experienceCategoriesContentPromoted2 = response.categoriesContentPromoted2Res
  })

  // console.log("forDebug", experienceCategoriesContentDefault)
  // console.log("forDebug", experienceCategoriesContentPromoted)
  // console.log("forDebug", experienceCategoriesContentPromoted2)

  profile = await DataFetchHelper.fetchPageProfileData( redis, app_data.page_id )

  destinations = await DataFetchHelper.fetchDestinationsData( redis, app_data.page_id )

  collections = await DataFetchHelper.fetchColllectionsData( redis, app_data.page_id )

  manifest = MethodHelper.initManifest( head, app, '/?source=pwa' );
  manifest = MethodHelper.extendManifest( manifest, head );

  const json = JSON.stringify( manifest );
  const fs = require( 'fs' );
  fs.writeFile( './public/manifest.json', json, ( err ) => {
    if ( err ) {
      // eslint-disable-next-line no-console
      console.error( err )
      return
    }
  });

  if ( app.searchbarBgColor != null )
    rgbSearchbarColor = MethodHelper.hexToRgb( app.searchbarBgColor );
  if ( app.designColor )
    rgbDesignColor = MethodHelper.hexToRgb( app.designColor );

  redis.disconnect(false)

  return {
    props: {
      isHomepage: true,
      app_data: app_data,
      head: head,
      page: page,
      app: app,
      builder: app != null && app.builder != null ? app.builder : null,
      navigation: navigation,
      content: content,
      html_sections: html_sections,
      script_sections: script_sections,
      locations: locations,
      footer: footer,
      domain: domain,
      subdomain: subdomain,
      parts: parts,
      slug_data: slug_data,
      rgbDesignColor: rgbDesignColor,
      rgbSearchbarColor: rgbSearchbarColor,
      isDesktop: isDesktop,
      isMobile: isMobile,
      isTablet: isTablet,
      distincts: distincts,
      profile: profile,
      destinations: destinations,
      collections: collections,
      categoriesContentDefault: categoriesContentDefault,
      categoriesContentPromoted: categoriesContentPromoted,
      categoriesContentPromoted2: categoriesContentPromoted2,
      experienceCategoriesContentDefault: experienceCategoriesContentDefault,
      experienceCategoriesContentPromoted: experienceCategoriesContentPromoted,
      experienceCategoriesContentPromoted2: experienceCategoriesContentPromoted2
    }
  }
})
